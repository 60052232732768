import { datalayerPush } from '~/modules/gtm'
import { $analytics, segmentEnabled } from '~/modules/asegment'
import {
  cobrandType,
  memberPlanType,
  memberPlansType,
  reactivationStepDataType,
} from '~/types'

export const useSignupStore = defineStore('signup', () => {
  const trackingStore = useTrackingStore()
  const userStore = useUserStore()
  const siteStore = useSiteStore()
  const router = useRouter()

  const currentStepName = ref('')
  const passwordlessCode = ref('')
  const signupError = ref(false)

  const reactivationData = ref<reactivationStepDataType | null>()

  const productName = 'The Lending Score'
  const productCode = 'tls'
  const memberUrl = import.meta.env.VITE_APP_TLS_URL

  const memberPlans = ref<memberPlansType | null>(null)
  const cobrand = ref<cobrandType | null>(null)

  const selectedMemberPlanType = ref(null)

  const signupSteps: string[] = [
    'AccountStep',
    'PersonalStep',
    'IdentityStep',
    'BillingStep',
    'ConfirmationStep',
  ]

  const getSelectedMemberPlan = computed(() =>
    memberPlans.value?.plans.find(
      (x) => x.planType === selectedMemberPlanType.value
    )
  )

  const passwordlessLoginUrl = computed(
    () =>
      `${import.meta.env.VITE_APP_FUSIONAUTH_URL}/oauth2/passwordless/${
        passwordlessCode.value
      }/?client_id=${
        import.meta.env.VITE_APP_FUSIONAUTH_CLIENTID
      }&redirect_uri=${
        import.meta.env.VITE_APP_TLS_URL
      }/auth&response_type=code&scope=openid%20offline_access`
  )

  const setMemberPlans = (data: any) => {
    if (!data?.memberPlans) return

    memberPlans.value = data.memberPlans

    /**
     *
     * Set default plan
     */
    const availablePlans = data.memberPlans?.planTypes.filter(
      (x: string) => x !== 'SPONSORED'
    )

    if (availablePlans.length < 2) {
      selectedMemberPlanType.value = availablePlans[0]
    } else {
      selectedMemberPlanType.value = data.memberPlans.plans.find(
        (x: memberPlanType) => x?.isDefault
      )?.planType
    }

    cobrand.value = data?.cobrand
    siteStore.customerSupportHours = data?.support?.supportHours
    siteStore.customerSupportPhone = data?.support?.supportPhoneNumber
  }

  const getCampaign = async () => {
    if (import.meta.env.VITE_APP_USE_MOCK === 'true') {
      const data = await (
        await import('../../mocks/campaignTrial.json')
      ).default

      setMemberPlans(data)

      return true
    }

    try {
      const url = new URL(
        `${import.meta.env.VITE_APP_TLS_API}/v1/site/campaign`
      )

      const userPID = userStore.userPID
        ? userStore.userPID
        : !import.meta.env.SSR &&
            localStorageAvailable() &&
            localStorage.getItem('PID')
          ? localStorage.getItem('PID')
          : null

      Object.entries({
        productCode: import.meta.env.VITE_APP_PRODUCT_CODE,
        ...(userPID && {
          pid: userPID,
        }),
        ...trackingStore.trackingParams,
      }).forEach(([k, v]) => url.searchParams.append(k, v))

      const resp = await fetch(url.href)

      if (resp.status === 403) {
        router.replace({ name: 'coming-soon' })
        return false
      }

      const data = await resp.json()

      if (resp.ok) {
        setMemberPlans(data)

        trackingStore.saveTrackingParam('PID', data?.pid)

        let anonymousId

        if (segmentEnabled.value) {
          const segmentUser = await $analytics.user()
          anonymousId = await segmentUser?.anonymousId()
        }

        datalayerPush({
          ...(anonymousId && {
            anonymous_id: anonymousId,
          }),
          ...trackingStore.gtmTrackingData,
        })

        return true
      } else {
        const pidInvalidError =
          data.errors.findIndex(
            (x) =>
              x.code === 'PID_INVALID' ||
              x.code === 'ID_CONFLICT' ||
              x.field === 'pid'
          ) > -1

        if (pidInvalidError) {
          userStore.clearUserPID()
          await getCampaign()
        } else {
          const intlSignupError =
            data.errors.findIndex((x) => x.code === 'LOCATION_NOT_ALLOWED') > -1

          if (intlSignupError) {
            signupError.value = true
            return false
          }
        }
      }

      return false
    } catch (error) {
      userStore.userPID = ''
      if (!import.meta.env.SSR && localStorageAvailable())
        localStorage.removeItem('PID')

      router.replace({ name: 'coming-soon' })
      return false
    }
  }

  const startSignupStepListener = () => {
    document.addEventListener('cd-signup-next-step', (e) => {
      userStore.userPID = (<CustomEvent>e).detail?.PID
      currentStepName.value = (<CustomEvent>e).detail.step
      passwordlessCode.value = (<CustomEvent>e).detail?.passwordlessCode
      selectedMemberPlanType.value = (<CustomEvent>e).detail?.memberPlan
    })
  }

  const stopSignupStepListener = () => {
    currentStepName.value = ''
    document.removeEventListener('cd-signup-next-step', null)
  }

  const startReactivationStepListener = () => {
    document.addEventListener('cd-reactivation-next-step', (e) => {
      currentStepName.value = (<CustomEvent>e).detail.step
      passwordlessCode.value = (<CustomEvent>e).detail?.passwordlessCode

      reactivationData.value = {
        accountStatus: (<CustomEvent>e).detail?.accountStatus,
        balance: (<CustomEvent>e).detail?.balance,
        chargeDisplay: (<CustomEvent>e).detail?.chargeDisplay,
        nextStatementOpenDate: (<CustomEvent>e).detail?.nextStatementOpenDate,
      }
    })
  }

  const stopReactivationStepListener = () => {
    currentStepName.value = ''
    document.removeEventListener('cd-reactivation-next-step', null)
  }

  const startRecoveryStepListener = () => {
    document.addEventListener('cd-recovery-next-step', (e) => {
      currentStepName.value = (<CustomEvent>e).detail.step
    })
  }

  const stopRecoveryStepListener = () => {
    currentStepName.value = ''
    document.removeEventListener('cd-recovery-next-step', null)
  }

  const $reset = () => {
    currentStepName.value = ''
    passwordlessCode.value = ''
    memberPlans.value = null
    cobrand.value = null

    selectedMemberPlanType.value = null
  }

  return {
    signupError,
    signupSteps,
    productName,
    productCode,
    memberUrl,
    currentStepName,
    passwordlessCode,
    passwordlessLoginUrl,
    memberPlans,
    cobrand,
    reactivationData,
    selectedMemberPlanType,
    getSelectedMemberPlan,
    getCampaign,
    startSignupStepListener,
    stopSignupStepListener,
    startReactivationStepListener,
    stopReactivationStepListener,
    startRecoveryStepListener,
    stopRecoveryStepListener,
    $reset,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useSignupStore, import.meta.hot))
